import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastOptions } from "./utils/toastify";
import RouteAuth from "./utils/RouteAuth";
import Loader from "./components/Loader/Loader";

const HomePage = React.lazy(() => import("./pages/HomePage/HomePage"));
const AccountPages = React.lazy(() => import("./pages/AccountPages/AccountPages"));
const BranchPages = React.lazy(() => import("./pages/BranchPages/BranchPages"));
const CategoryPages = React.lazy(() => import("./pages/CategoryPages/CategoryPages"));
const OrderPages = React.lazy(() => import("./pages/OrderPages/OrderPages"));
const ProductPages = React.lazy(() => import("./pages/ProductPages/ProductPages"));
const ShopPage = React.lazy(() => import("./pages/ShopPage/ShopPage"));
const AnalyticsPage = React.lazy(() => import("./pages/AnalyticsPage/AnalyticsPage"));
const CartPage = React.lazy(() => import("./pages/OrderPages/CartPage/CartPage"));

toast.configure(toastOptions);

const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Router>
        <Header />

        <Switch>
          <Route exact path={"/"}>
            <HomePage />
          </Route>
          <RouteAuth role={1} exact path={"/cart"}>
            <CartPage />
          </RouteAuth>
          <RouteAuth role={1} exact path={"/shop"}>
            <ShopPage />
          </RouteAuth>
          <RouteAuth role={2} exact path={"/analytics"}>
            <AnalyticsPage />
          </RouteAuth>
          <Route path={"/account"}>
            <AccountPages />
          </Route>
          <Route path={"/branches"}>
            <BranchPages />
          </Route>
          <Route path={"/categories"}>
            <CategoryPages />
          </Route>
          <Route path={"/orders"}>
            <OrderPages />
          </Route>
          <Route path={"/products"}>
            <ProductPages />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </React.Suspense>
  );
};

export default App;
