export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const RESET_PASSWORD_CONFIRMATION_REQUEST = "RESET_PASSWORD_CONFIRMATION_REQUEST";
export const RESET_PASSWORD_CONFIRMATION_SUCCESS = "RESET_PASSWORD_CONFIRMATION_SUCCESS";
export const RESET_PASSWORD_CONFIRMATION_FAIL = "RESET_PASSWORD_CONFIRMATION_FAIL";
export const RESET_PASSWORD_CONFIRMATION_RESET = "RESET_PASSWORD_CONFIRMATION_RESET";

export const RESET_PASSWORD_LINK_VERIFICATION_REQUEST = "RESET_PASSWORD_LINK_VERIFICATION_REQUEST";
export const RESET_PASSWORD_LINK_VERIFICATION_SUCCESS = "RESET_PASSWORD_LINK_VERIFICATION_SUCCESS";
export const RESET_PASSWORD_LINK_VERIFICATION_FAIL = "RESET_PASSWORD_LINK_VERIFICATION_FAIL";
export const RESET_PASSWORD_LINK_VERIFICATION_RESET = "RESET_PASSWORD_LINK_VERIFICATION_RESET";
