import {
  RESET_PASSWORD_CONFIRMATION_FAIL,
  RESET_PASSWORD_CONFIRMATION_REQUEST,
  RESET_PASSWORD_CONFIRMATION_RESET,
  RESET_PASSWORD_CONFIRMATION_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LINK_VERIFICATION_FAIL,
  RESET_PASSWORD_LINK_VERIFICATION_REQUEST,
  RESET_PASSWORD_LINK_VERIFICATION_RESET,
  RESET_PASSWORD_LINK_VERIFICATION_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
} from "../constants/verification.constants";

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_SUCCESS:
      return { success: true };

    case RESET_PASSWORD_FAIL:
      return { error: action.payload };

    case RESET_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const resetPasswordConfirmationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_CONFIRMATION_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_CONFIRMATION_SUCCESS:
      return { success: true };

    case RESET_PASSWORD_CONFIRMATION_FAIL:
      return { error: action.payload };

    case RESET_PASSWORD_CONFIRMATION_RESET:
      return {};

    default:
      return state;
  }
};

export const resetPasswordLinkVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_LINK_VERIFICATION_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_LINK_VERIFICATION_SUCCESS:
      return { success: true, valid: action.payload.valid };

    case RESET_PASSWORD_LINK_VERIFICATION_FAIL:
      return { error: action.payload };

    case RESET_PASSWORD_LINK_VERIFICATION_RESET:
      return {};

    default:
      return state;
  }
};
