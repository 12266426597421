import React, { useState, useEffect } from "react";
import "./Loader.scss";

const LOADER_DELAY = 500;

const Loader = () => {
  return (
    <div id='pageLoader'>
      <span></span>
    </div>
  );
};

export default Loader;
