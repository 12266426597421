import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const RouteAuth = ({ children, role = 0, ...rest }) => {
  const { userInfo } = useSelector((state) => state.userLogin);

  const isAuthenticated = () => {
    if (userInfo && userInfo.role >= role) return true;
    return false;
  };

  return <Route {...rest}>{isAuthenticated() ? children : <Redirect to='/account/login' />}</Route>;
};

export default RouteAuth;
