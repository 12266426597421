import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_RESET,
  ORDER_UPDATE_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_RESET,
  ORDER_BULK_UPDATE_REQUEST,
  ORDER_BULK_UPDATE_SUCCESS,
  ORDER_BULK_UPDATE_FAIL,
  ORDER_BULK_UPDATE_RESET,
  ORDER_ANALYTICS_REQUEST,
  ORDER_ANALYTICS_SUCCESS,
  ORDER_ANALYTICS_FAIL,
  ORDER_ANALYTICS_RESET,
} from "../constants/order.constants";

export const orderListReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true };

    case ORDER_LIST_SUCCESS:
      return { success: true, data: action.payload };

    case ORDER_LIST_FAIL:
      return { error: action.payload };

    case ORDER_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };

    case ORDER_CREATE_SUCCESS:
      return { success: true, data: action.payload };

    case ORDER_CREATE_FAIL:
      return { error: action.payload };

    case ORDER_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const orderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loading: true };

    case ORDER_UPDATE_SUCCESS:
      return { success: true, order: action.payload };

    case ORDER_UPDATE_FAIL:
      return { error: action.payload };

    case ORDER_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const orderBulkUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_BULK_UPDATE_REQUEST:
      return { loading: true };

    case ORDER_BULK_UPDATE_SUCCESS:
      return { success: true, message: action.payload.message };

    case ORDER_BULK_UPDATE_FAIL:
      return { error: action.payload };

    case ORDER_BULK_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const orderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true };

    case ORDER_DETAILS_SUCCESS:
      return { data: action.payload };

    case ORDER_DETAILS_FAIL:
      return { error: action.payload };

    case ORDER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const analyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ANALYTICS_REQUEST:
      return { loading: true };

    case ORDER_ANALYTICS_SUCCESS:
      return { data: action.payload };

    case ORDER_ANALYTICS_FAIL:
      return { error: action.payload };

    case ORDER_ANALYTICS_RESET:
      return {};

    default:
      return state;
  }
};
