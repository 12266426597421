import {
  VARIANT_CREATE_BULK_FAIL,
  VARIANT_CREATE_BULK_REQUEST,
  VARIANT_CREATE_BULK_RESET,
  VARIANT_CREATE_BULK_SUCCESS,
  VARIANT_CREATE_FAIL,
  VARIANT_CREATE_REQUEST,
  VARIANT_CREATE_SUCCESS,
  VARIANT_DELETE_FAIL,
  VARIANT_DELETE_REQUEST,
  VARIANT_DELETE_SUCCESS,
  VARIANT_DETAILS_FAIL,
  VARIANT_DETAILS_REQUEST,
  VARIANT_DETAILS_SUCCESS,
  VARIANT_LIST_FAIL,
  VARIANT_LIST_REQUEST,
  VARIANT_LIST_RESET,
  VARIANT_LIST_SUCCESS,
  VARIANT_RESET,
  VARIANT_RESET_FORMS,
  VARIANT_UPDATE_FAIL,
  VARIANT_UPDATE_REQUEST,
  VARIANT_UPDATE_SUCCESS,
} from "../constants/variant.constants";

export const variantListReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_LIST_REQUEST:
      return { loading: true };

    case VARIANT_LIST_SUCCESS:
      return { data: action.payload };

    case VARIANT_LIST_FAIL:
      return { error: action.payload };

    case VARIANT_RESET:
    case VARIANT_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const variantCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_CREATE_REQUEST:
      return { loading: true };

    case VARIANT_CREATE_SUCCESS:
      return { data: action.payload, success: true };

    case VARIANT_CREATE_FAIL:
      return { error: action.payload };

    case VARIANT_RESET_FORMS:
    case VARIANT_RESET:
      return {};

    default:
      return state;
  }
};

export const variantCreateBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_CREATE_BULK_REQUEST:
      return { loading: true };

    case VARIANT_CREATE_BULK_SUCCESS:
      return { data: action.payload, success: true };

    case VARIANT_CREATE_BULK_FAIL:
      return { error: action.payload };

    case VARIANT_RESET_FORMS:
    case VARIANT_CREATE_BULK_RESET:
    case VARIANT_RESET:
      return {};

    default:
      return state;
  }
};

export const variantUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_UPDATE_REQUEST:
      return { loading: true };

    case VARIANT_UPDATE_SUCCESS:
      return { data: action.payload, success: true };

    case VARIANT_UPDATE_FAIL:
      return { error: action.payload };

    case VARIANT_RESET_FORMS:
    case VARIANT_RESET:
      return {};

    default:
      return state;
  }
};

export const variantDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_DELETE_REQUEST:
      return { loading: true };

    case VARIANT_DELETE_SUCCESS:
      return { data: action.payload, success: true };

    case VARIANT_DELETE_FAIL:
      return { error: action.payload };

    case VARIANT_RESET_FORMS:
    case VARIANT_RESET:
      return {};

    default:
      return state;
  }
};

export const variantDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANT_DETAILS_REQUEST:
      return { loading: true };

    case VARIANT_DETAILS_SUCCESS:
      return { data: action.payload };

    case VARIANT_DETAILS_FAIL:
      return { error: action.payload };

    case VARIANT_RESET_FORMS:
    case VARIANT_RESET:
      return {};

    default:
      return state;
  }
};
