const { SIDEBAR_EXPAND_UPDATE, SIDEBAR_EXPAND_RESET } = require("../constants/sidebar.constants");

export const SIDEBAR_EXPAND_DEFAULT = {
  branch: true,
  sortBy: true,
  category: false,
  color: false,
  s01: false,
  s02: false,
  country: false,
  farm: false,
  orderStatus: false,
  deliveryDate: false,
};

export const sidebarExpandReducer = (state = SIDEBAR_EXPAND_DEFAULT, action) => {
  switch (action.type) {
    case SIDEBAR_EXPAND_UPDATE:
      return { ...action.payload };

    case SIDEBAR_EXPAND_RESET:
      return { ...SIDEBAR_EXPAND_DEFAULT };

    default:
      return state;
  }
};
