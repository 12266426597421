import { toastOptions } from "./toastify";

const { toast } = require("react-toastify");

export const errorDispatch = (dispatch, error, actionType, cancelAutoClose = false) => {
  const errMsg = error.response && error.response.data.message ? error.response.data.message : error.message;

  dispatch({
    type: actionType,
    payload: errMsg,
  });

  toast.error(errMsg, {
    ...toastOptions,
    autoClose: cancelAutoClose ? false : toastOptions.autoClose,
    closeButton: cancelAutoClose ? false : true,
  });
};
