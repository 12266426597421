export const BRANCHES_LIST_REQUEST = "BRANCHES_LIST_REQUEST";
export const BRANCHES_LIST_SUCCESS = "BRANCHES_LIST_SUCCESS";
export const BRANCHES_LIST_FAIL = "BRANCHES_LIST_FAIL";

export const BRANCH_ADD_REQUEST = "BRANCH_ADD_REQUEST";
export const BRANCH_ADD_SUCCESS = "BRANCH_ADD_SUCCESS";
export const BRANCH_ADD_FAIL = "BRANCH_ADD_FAIL";
export const BRANCH_ADD_RESET = "BRANCH_ADD_RESET";

export const BRANCH_UPDATE_REQUEST = "BRANCH_UPDATE_REQUEST";
export const BRANCH_UPDATE_SUCCESS = "BRANCH_UPDATE_SUCCESS";
export const BRANCH_UPDATE_FAIL = "BRANCH_UPDATE_FAIL";
export const BRANCH_UPDATE_RESET = "BRANCH_UPDATE_RESET";

export const BRANCH_DETAILS_REQUEST = "BRANCH_DETAILS_REQUEST";
export const BRANCH_DETAILS_SUCCESS = "BRANCH_DETAILS_SUCCESS";
export const BRANCH_DETAILS_FAIL = "BRANCH_DETAILS_FAIL";

export const BRANCH_DELETE_REQUEST = "BRANCH_DELETE_REQUEST";
export const BRANCH_DELETE_SUCCESS = "BRANCH_DELETE_SUCCESS";
export const BRANCH_DELETE_FAIL = "BRANCH_DELETE_FAIL";

export const BRANCH_RESET = "";
