import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_RESET,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_LIST_ALL_FAIL,
  CATEGORY_LIST_ALL_REQUEST,
  CATEGORY_LIST_ALL_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_RESET,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
} from "../constants/category.constants";

export const categoriesListReducer = (state = { data: { categories: [] } }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, data: { categories: [] } };
    case CATEGORY_LIST_SUCCESS:
      return { data: action.payload };
    case CATEGORY_LIST_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

// For select menus
export const categoriesListAllReducer = (state = { data: { categories: [] } }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_ALL_REQUEST:
      return { loading: true, data: { categories: [] } };
    case CATEGORY_LIST_ALL_SUCCESS:
      return { data: action.payload };
    case CATEGORY_LIST_ALL_FAIL:
      return { ...state, error: action.payload };
    case CATEGORY_RESET:
      return { data: { categories: [] } };
    default:
      return state;
  }
};

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return { loading: true };
    case CATEGORY_CREATE_SUCCESS:
      return { success: true, data: action.payload };
    case CATEGORY_CREATE_FAIL:
      return { error: action.payload };
    case CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return { loading: true };
    case CATEGORY_DETAILS_SUCCESS:
      return { category: action.payload.data };
    case CATEGORY_DETAILS_FAIL:
      return { error: action.payload };
    case CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true };
    case CATEGORY_UPDATE_SUCCESS:
      return { category: action.payload, success: true };
    case CATEGORY_UPDATE_FAIL:
      return { error: action.payload };
    case CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case CATEGORY_DELETE_SUCCESS:
      return { category: action.payload, success: true };
    case CATEGORY_DELETE_FAIL:
      return { error: action.payload };
    case CATEGORY_DELETE_RESET:
    case CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};
