const { Zoom } = require("react-toastify");

const toastOptions = {
  position: "bottom-center",
  autoClose: 4000,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: false,
  transition: Zoom,
  limit: 3,
};

module.exports = {
  toastOptions,
};
