import {
  USER_ACCOUNT_REQUEST_FAIL,
  USER_ACCOUNT_REQUEST_REQUEST,
  USER_ACCOUNT_REQUEST_SUCCESS,
  USER_BLOCK_FAIL,
  USER_BLOCK_REQUEST,
  USER_BLOCK_RESET,
  USER_BLOCK_SUCCESS,
  USER_CHANGE_PASS_FAIL,
  USER_CHANGE_PASS_REQUEST,
  USER_CHANGE_PASS_RESET,
  USER_CHANGE_PASS_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/user.constants";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { errorDispatch } from "../utils/reduxErrorHandler";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;
const url = `${REACT_APP_API_URL}/users`;

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(`${url}/login`, { email, password });
    const decoded = jwt_decode(data.token);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { ...decoded, token: data.token },
    });

    localStorage.setItem("userInfo", JSON.stringify({ ...decoded, token: data.token }));
  } catch (error) {
    errorDispatch(dispatch, error, USER_LOGIN_FAIL);
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");

  dispatch({
    type: USER_LOGOUT,
  });
};

export const getUsers = (searchOptions) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `${url}?page=${searchOptions.page}&sortBy=${searchOptions.sortBy}&sortOrder=${searchOptions.sortOrder}&search=${searchOptions.search}`
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    errorDispatch(dispatch, error, USER_LIST_FAIL, true);
  }
};

export const createUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_CREATE_REQUEST });

    const { data } = await axios.post(url, user);

    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });

    toast.success("User has been created successfully!");
  } catch (error) {
    errorDispatch(dispatch, error, USER_CREATE_FAIL);
  }
};

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const { data } = await axios.put(url, user);

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });

    toast.success("User has been updated successfully!");
  } catch (error) {
    errorDispatch(dispatch, error, USER_UPDATE_FAIL);
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_RESET });
    dispatch({ type: USER_DETAILS_REQUEST });

    const { data } = await axios.get(`${url}/${id}`);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    errorDispatch(dispatch, error, USER_DETAILS_FAIL);
  }
};

export const updateAccountStatus = (userId) => async (dispatch) => {
  try {
    dispatch({ type: USER_BLOCK_RESET });
    dispatch({ type: USER_BLOCK_REQUEST });

    const { data } = await axios.post(`${url}/blocking`, { userId });

    dispatch({
      type: USER_BLOCK_SUCCESS,
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    errorDispatch(dispatch, error, USER_BLOCK_FAIL);
  }
};

export const changePass = (oldPass, newPass) => async (dispatch) => {
  try {
    dispatch({ type: USER_CHANGE_PASS_RESET });
    dispatch({ type: USER_CHANGE_PASS_REQUEST });

    const { data } = await axios.post(`${url}/change-pass`, { newPass, oldPass });

    dispatch({
      type: USER_CHANGE_PASS_SUCCESS,
    });

    toast.success(data.message);
  } catch (error) {
    errorDispatch(dispatch, error, USER_CHANGE_PASS_FAIL);
  }
};

export const accountRequest = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_ACCOUNT_REQUEST_REQUEST });

    const { data } = await axios.post(`${url}/account-request`, userData);

    dispatch({ type: USER_ACCOUNT_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    errorDispatch(dispatch, error, USER_ACCOUNT_REQUEST_FAIL);
  }
};
