import {
  USER_ACCOUNT_REQUEST_FAIL,
  USER_ACCOUNT_REQUEST_REQUEST,
  USER_ACCOUNT_REQUEST_SUCCESS,
  USER_BLOCK_FAIL,
  USER_BLOCK_REQUEST,
  USER_BLOCK_RESET,
  USER_BLOCK_SUCCESS,
  USER_CHANGE_PASS_FAIL,
  USER_CHANGE_PASS_REQUEST,
  USER_CHANGE_PASS_RESET,
  USER_CHANGE_PASS_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_FORM_RESET,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/user.constants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };

    case USER_CREATE_SUCCESS:
      return { data: action.payload, success: true };

    case USER_CREATE_FAIL:
      return { error: action.payload };

    case USER_FORM_RESET:
      return {};

    default:
      return state;
  }
};

export const userBlockReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_BLOCK_REQUEST:
      return { loading: true };

    case USER_BLOCK_SUCCESS:
      return { data: action.payload, success: true };

    case USER_BLOCK_FAIL:
      return { error: action.payload };

    case USER_BLOCK_RESET:
    case USER_FORM_RESET:
      return {};

    default:
      return state;
  }
};

export const userListReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { ...state, loading: true };

    case USER_LIST_SUCCESS:
      return { data: action.payload, success: true };

    case USER_LIST_FAIL:
      return { ...state, error: action.payload };

    case USER_FORM_RESET:
      return { data: [] };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };

    case USER_DETAILS_SUCCESS:
      return { data: action.payload };

    case USER_DETAILS_FAIL:
      return { error: action.payload };

    case USER_FORM_RESET:
    case USER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };

    case USER_UPDATE_SUCCESS:
      return { data: action.payload, success: true };

    case USER_UPDATE_FAIL:
      return { error: action.payload };

    case USER_FORM_RESET:
      return {};

    default:
      return state;
  }
};

export const userChangePassReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CHANGE_PASS_REQUEST:
      return { loading: true };

    case USER_CHANGE_PASS_SUCCESS:
      return { success: true };

    case USER_CHANGE_PASS_FAIL:
      return { error: action.payload };

    case USER_CHANGE_PASS_RESET:
      return {};

    default:
      return state;
  }
};

export const userAccountRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACCOUNT_REQUEST_REQUEST:
      return { loading: true };

    case USER_ACCOUNT_REQUEST_SUCCESS:
      return { success: true, message: action.payload.message };

    case USER_ACCOUNT_REQUEST_FAIL:
      return { error: action.payload };

    default:
      return state;
  }
};
