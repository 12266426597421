import {
  BRANCHES_LIST_FAIL,
  BRANCHES_LIST_REQUEST,
  BRANCHES_LIST_SUCCESS,
  BRANCH_ADD_REQUEST,
  BRANCH_ADD_SUCCESS,
  BRANCH_ADD_FAIL,
  BRANCH_ADD_RESET,
  BRANCH_UPDATE_REQUEST,
  BRANCH_UPDATE_SUCCESS,
  BRANCH_UPDATE_FAIL,
  BRANCH_UPDATE_RESET,
  BRANCH_DETAILS_REQUEST,
  BRANCH_DETAILS_FAIL,
  BRANCH_DETAILS_SUCCESS,
  BRANCH_DELETE_REQUEST,
  BRANCH_DELETE_SUCCESS,
  BRANCH_DELETE_FAIL,
  BRANCH_RESET,
} from "../constants/branch.constants";

export const branchesListReducer = (state = { branches: [] }, action) => {
  switch (action.type) {
    case BRANCHES_LIST_REQUEST:
      return { loading: true, branches: [] };
    case BRANCHES_LIST_SUCCESS:
      return { branches: action.payload.branches };
    case BRANCHES_LIST_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const branchCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_ADD_REQUEST:
      return { loading: true };
    case BRANCH_ADD_SUCCESS:
      return { loading: true, success: true, branch: action.payload };
    case BRANCH_ADD_FAIL:
      return { loading: true, error: action.payload };
    case BRANCH_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const branchUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_UPDATE_REQUEST:
      return { loading: true };
    case BRANCH_UPDATE_SUCCESS:
      return { loading: true, success: true, data: action.payload };
    case BRANCH_UPDATE_FAIL:
      return { loading: true, error: action.payload };
    case BRANCH_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const branchDetailsReducer = (state = { branch: {} }, action) => {
  switch (action.type) {
    case BRANCH_DETAILS_REQUEST:
      return { loading: true, ...state };
    case BRANCH_DETAILS_SUCCESS:
      return { success: true, branch: action.payload };
    case BRANCH_DETAILS_FAIL:
      return { error: action.payload };
    case BRANCH_RESET:
      return { branch: {} };
    default:
      return state;
  }
};

export const branchDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_DELETE_REQUEST:
      return { loading: true };
    case BRANCH_DELETE_SUCCESS:
      return { success: true, successMessage: action.payload };
    case BRANCH_DELETE_FAIL:
      return { error: action.payload };
    case BRANCH_RESET:
      return {};
    default:
      return state;
  }
};
