import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_RESET } from "../constants/cart.constants";

export const cartReducer = (state = { items: [], itemCount: 0 }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      return {
        items: action.payload.items,
        itemCount: action.payload.itemCount,
        totalPrice: action.payload.totalPrice,
        success: true,
      };

    case CART_REMOVE_ITEM:
      return {
        items: action.payload.items,
        itemCount: action.payload.itemCount,
        totalPrice: action.payload.totalPrice,
        success: true,
      };

    case CART_RESET:
      return { items: [], itemCount: 0, totalPrice: 0 };

    default:
      return state;
  }
};
