import React, { useState } from "react";
import logo from "../../images/white-orchide.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import "./Header.scss";
import { logout } from "../../actions/user.actions";

const Header = () => {
  const [mobMenuOpened, setMobMenuOpened] = useState(false);

  const dispatch = useDispatch();

  const toggleMenu = () => {
    setMobMenuOpened(!mobMenuOpened);
  };

  const closeMenu = () => {
    if (mobMenuOpened) {
      setMobMenuOpened(false);
    }
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const { userInfo } = useSelector((state) => state.userLogin);

  const authBarHandler = () => {
    if (userInfo) {
      return (
        <React.Fragment>
          <li className='aks-dropdown'>
            <span className='dropdown-title'>
              {userInfo.name} <i className='fas fa-angle-down'></i>
            </span>

            <ul className='dropdown-items'>
              <li>
                <Link to={"/account/settings"}>Settings</Link>
              </li>
              <li>
                <a href='/' onClick={logoutHandler}>
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </React.Fragment>
      );
    } else {
      return (
        <li>
          <Link to={"/account/login"}>
            Login <span className='fa fa-sign-in'></span>
          </Link>
        </li>
      );
    }
  };

  return (
    <menu>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2 col-md-4 col-8 align-self-center'>
            <Link to={"/"}>
              <img src={logo} alt='White Orchide Logo' className='img-fluid logo' />
            </Link>
          </div>
          <div className='col-lg-10 col-md-8 col-4 align-self-center menu-right-part'>
            {!userInfo ? (
              <NavLink className='login-lnk' to={"/account/login"}>
                Login <span className='fa fa-sign-in'></span>
              </NavLink>
            ) : (
              <React.Fragment>
                <span className='fa fa-bars only-mob' onClick={toggleMenu}></span>

                <div className={`menu-wrapper ${mobMenuOpened ? "mob-menu-opened" : ""}`}>
                  <img src={logo} alt='White Orchide Logo' className='mob-logo only-mob img-fluid' />

                  <ul className='navigation-links navigation-list' onClick={closeMenu}>
                    {userInfo?.role >= 2 && (
                      <React.Fragment>
                        <li>
                          <NavLink activeClassName='active' to={"/analytics"}>
                            Analytics
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName='active' to={"/branches"}>
                            Branches
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName='active' to={"/categories"}>
                            Categories
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName='active' to={"/products"}>
                            Products
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName='active' to={"/account"}>
                            Accounts
                          </NavLink>
                        </li>
                      </React.Fragment>
                    )}
                    {userInfo?.role >= 1 && (
                      <React.Fragment>
                        <li>
                          <NavLink activeClassName='active' to={"/orders"}>
                            Orders
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName='active' to={"/shop"}>
                            Shop
                          </NavLink>
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                  <ul className='navigation-icons navigation-list' onClick={closeMenu}>
                    {userInfo?.role >= 1 && (
                      <li>
                        <NavLink to={"/cart"}>
                          Cart <span className='fa fa-shopping-cart'></span>
                        </NavLink>
                      </li>
                    )}
                    {authBarHandler()}
                  </ul>

                  <div className='only-mob menu-close' onClick={closeMenu}>
                    Close Menu
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </menu>
  );
};

export default Header;
