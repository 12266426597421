import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userAccountRequestReducer,
  userBlockReducer,
  userChangePassReducer,
  userCreateReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userUpdateReducer,
} from "./reducers/user.reducers";
import {
  branchCreateReducer,
  branchDeleteReducer,
  branchDetailsReducer,
  branchesListReducer,
  branchUpdateReducer,
} from "./reducers/branch.reducers";
import axios from "axios";
import {
  categoriesListAllReducer,
  categoriesListReducer,
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryDetailsReducer,
  categoryUpdateReducer,
} from "./reducers/category.reducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  productUpdateReducer,
} from "./reducers/product.reducers";
import { imageUploadReducer } from "./reducers/upload.reducers";

import {
  variantCreateBulkReducer,
  variantCreateReducer,
  variantDeleteReducer,
  variantDetailsReducer,
  variantListReducer,
  variantUpdateReducer,
} from "./reducers/variant.reducers";
import { cartReducer } from "./reducers/cart.reducers";
import {
  analyticsReducer,
  orderBulkUpdateReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderListReducer,
  orderUpdateReducer,
} from "./reducers/order.reducers";
import {
  resetPasswordConfirmationReducer,
  resetPasswordLinkVerificationReducer,
  resetPasswordReducer,
} from "./reducers/verification.reducers";
import { sidebarExpandReducer, SIDEBAR_EXPAND_DEFAULT } from "./reducers/sidebar.reducers";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartItemsFromStorage = localStorage.getItem("wo-cart")
  ? JSON.parse(localStorage.getItem("wo-cart"))
  : { items: [], itemCount: 0, totalPrice: 0 };

const sidebarExpandFromStorage = localStorage.getItem("sidebarExpand")
  ? JSON.parse(localStorage.getItem("sidebarExpand"))
  : SIDEBAR_EXPAND_DEFAULT;

const reducer = combineReducers({
  imageUpload: imageUploadReducer,
  userLogin: userLoginReducer,
  userCreate: userCreateReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userBlock: userBlockReducer,
  userList: userListReducer,
  userChangePass: userChangePassReducer,
  userAccountRequest: userAccountRequestReducer,
  branchesList: branchesListReducer,
  branchCreate: branchCreateReducer,
  branchUpdate: branchUpdateReducer,
  branchDetails: branchDetailsReducer,
  branchDelete: branchDeleteReducer,
  categoriesList: categoriesListReducer,
  categoriesListAll: categoriesListAllReducer,
  categoryCreate: categoryCreateReducer,
  categoryDetails: categoryDetailsReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  variantList: variantListReducer,
  variantDetails: variantDetailsReducer,
  variantCreate: variantCreateReducer,
  variantCreateBulk: variantCreateBulkReducer,
  variantUpdate: variantUpdateReducer,
  variantDelete: variantDeleteReducer,
  orderList: orderListReducer,
  orderCreate: orderCreateReducer,
  orderUpdate: orderUpdateReducer,
  orderBulkUpdate: orderBulkUpdateReducer,
  orderDetails: orderDetailsReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordConfirmation: resetPasswordConfirmationReducer,
  resetPasswordLinkVerification: resetPasswordLinkVerificationReducer,
  analytics: analyticsReducer,
  cart: cartReducer,
  sidebarExpand: sidebarExpandReducer,
});

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  cart: cartItemsFromStorage,
  sidebarExpand: sidebarExpandFromStorage,
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

axios.interceptors.request.use((req) => {
  const token = store.getState().userLogin.userInfo?.token;
  if (token) {
    req.headers["Authorization"] = `Bearer ${token}`;
  }

  if (!req.headers["Content-Type"]) req.headers["Content-Type"] = "application/json";

  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      if (localStorage.getItem("userInfo")) localStorage.removeItem("userInfo");

      window.location.href = "/account/login";
    }

    return Promise.reject(err);
  }
);

export default store;
