export const VARIANT_LIST_REQUEST = "VARIANT_LIST_REQUEST";
export const VARIANT_LIST_SUCCESS = "VARIANT_LIST_SUCCESS";
export const VARIANT_LIST_FAIL = "VARIANT_LIST_FAIL";
export const VARIANT_LIST_RESET = "VARIANT_LIST_RESET";

export const VARIANT_DETAILS_REQUEST = "VARIANT_DETAILS_REQUEST";
export const VARIANT_DETAILS_SUCCESS = "VARIANT_DETAILS_SUCCESS";
export const VARIANT_DETAILS_FAIL = "VARIANT_DETAILS_FAIL";

export const VARIANT_CREATE_REQUEST = "VARIANT_CREATE_REQUEST";
export const VARIANT_CREATE_SUCCESS = "VARIANT_CREATE_SUCCESS";
export const VARIANT_CREATE_FAIL = "VARIANT_CREATE_FAIL";

export const VARIANT_CREATE_BULK_REQUEST = "VARIANT_CREATE_BULK_REQUEST";
export const VARIANT_CREATE_BULK_SUCCESS = "VARIANT_CREATE_BULK_SUCCESS";
export const VARIANT_CREATE_BULK_FAIL = "VARIANT_CREATE_BULK_FAIL";
export const VARIANT_CREATE_BULK_RESET = "VARIANT_CREATE_BULK_RESET";

export const VARIANT_UPDATE_REQUEST = "VARIANT_UPDATE_REQUEST";
export const VARIANT_UPDATE_SUCCESS = "VARIANT_UPDATE_SUCCESS";
export const VARIANT_UPDATE_FAIL = "VARIANT_UPDATE_FAIL";

export const VARIANT_DELETE_REQUEST = "VARIANT_DELETE_REQUEST";
export const VARIANT_DELETE_SUCCESS = "VARIANT_DELETE_SUCCESS";
export const VARIANT_DELETE_FAIL = "VARIANT_DELETE_FAIL";

export const VARIANT_RESET = "VARIANT_RESET";
export const VARIANT_RESET_FORMS = "VARIANT_RESET_FORMS";
