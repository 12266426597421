export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_BLOCK_REQUEST = "USER_BLOCK_REQUEST";
export const USER_BLOCK_SUCCESS = "USER_BLOCK_SUCCESS";
export const USER_BLOCK_FAIL = "USER_BLOCK_FAIL";
export const USER_BLOCK_RESET = "USER_BLOCK_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_CHANGE_PASS_REQUEST = "USER_CHANGE_PASS_REQUEST";
export const USER_CHANGE_PASS_SUCCESS = "USER_CHANGE_PASS_SUCCESS";
export const USER_CHANGE_PASS_FAIL = "USER_CHANGE_PASS_FAIL";
export const USER_CHANGE_PASS_RESET = "USER_CHANGE_PASS_RESET";

export const USER_ACCOUNT_REQUEST_REQUEST = "USER_ACCOUNT_REQUEST_REQUEST";
export const USER_ACCOUNT_REQUEST_SUCCESS = "USER_ACCOUNT_REQUEST_SUCCESS";
export const USER_ACCOUNT_REQUEST_FAIL = "USER_ACCOUNT_REQUEST_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_FORM_RESET = "USER_FORM_RESET";
